import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import(/* webpackChunkName: "group_home" */ '../components/Home.vue')
const Classify = () => import(/* webpackChunkName: "group_home" */ '../components/Classify.vue')
const Search = () => import(/* webpackChunkName: "group_search" */ '../components/Search.vue')
const Detail = () => import(/* webpackChunkName: "group_detail" */ '../components/Detail.vue')
const Mine = () => import(/* webpackChunkName: "group_mine" */ '../components/Mine.vue')
const Myinfo = () => import(/* webpackChunkName: "group_mine" */ '../components/Myinfo.vue')
const Orders = () => import(/* webpackChunkName: "group_mine" */ '../components/Orders.vue')
const Collect = () => import(/* webpackChunkName: "group_mine" */ '../components/Collect.vue')
const Wallet = () => import(/* webpackChunkName: "group_mine" */ '../components/Wallet.vue')
const Version = () => import(/* webpackChunkName: "group_mine" */ '../components/Version.vue')
const Gift = () => import(/* webpackChunkName: "group_mine" */ '../components/Gift.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', name: 'home', component: Home, meta: { keepAlive: true } },
    { path: '/classify', name: 'classify', component: Classify, meta: { keepAlive: true } },
    { path: '/search', name: 'search', component: Search, meta: { keepAlive: true } },
    { path: '/detail', name: 'detail', component: Detail, meta: { keepAlive: false } },
    { path: '/mine', name: 'mine', component: Mine, meta: { keepAlive: false } },
    { path: '/myinfo', name: 'myinfo', component: Myinfo, meta: { keepAlive: false } },
    { path: '/orders', name: 'orders', component: Orders, meta: { keepAlive: false } },
    { path: '/collect', name: 'collect', component: Collect, meta: { keepAlive: false } },
    { path: '/wallet', name: 'wallet', component: Wallet, meta: { keepAlive: false } },
    { path: '/version', name: 'version', component: Version, meta: { keepAlive: false } },
    { path: '/gift', name: 'gift', component: Gift, meta: { keepAlive: false } }
]

const router = new VueRouter({
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // if (to.path === '/detail') {
    //     from.meta.keepAlive = true
    // }
    // if (to.path === '/login') return next()
    // const tokenStr = window.sessionStorage.getItem('token')
    // if (!tokenStr) return next('/login')
    // next()
    next()
})

// router.afterEach((to, from, next) => {
//     if (to.path === '/detail') {
//         window.scrollTo(0, 0)
//     }
// })

export default router
