import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
// import 'font-awesome/css/font-awesome.min.css'
// 导入weui
// import 'weui'
// 配置axios
import axios from 'axios'
// 导入swiper
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 导入ly-tab
import LyTab from 'ly-tab'
// 导入v-focus
import './directives/directive'
// 导入clipboard
import VueClipboard from 'vue-clipboard2'
// 导入 vue-qr
import VueQr from 'vue-qr'
// 导入 vue-cookies
import VueCookies from 'vue-cookies'

Vue.prototype.$http = axios
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://www.8y8w.cn/api/discount_gift_api'

Vue.config.productionTip = false

Vue.use(vueSwiper)

Vue.use(LyTab)

Vue.use(VueClipboard)

Vue.use(VueQr)

Vue.use(VueCookies)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
